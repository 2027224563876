/* eslint-disable */

import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal
} from "reactstrap";

import classnames from "classnames";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
// Redux
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
import SweetAlert from "react-bootstrap-sweetalert"
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import { Fn_DisplayData, Fn_AddEditData, Fn_FillListData, Fn_GetReport, Fn_ChangeStateValue } from "../../store/functions";
import "./datatables.scss"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

function getdate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

const formatDate = (date) => {
  const d = new Date(date);
  const day = d.getDate().toString().padStart(2, '0');
  const month = d.toLocaleString('en-GB', { month: 'short' });
  const year = d.getFullYear();
  return `${day}-${month}-${year}`;
};

class pageAddEdit_MonthlyLoan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      formData: {},
      activeTab: 1,
      passedSteps: [1],
      //dropdowns
      Accountdetail: [{
        Name: ""
      }],
      RecipentDetail: [
        {
          Name: ""
        }
      ],
      prov: false,
      maritalStatus: [],
      accounttypevalue: 0,
      bloodGroup: [],
      gender: [],
      education: [],
      profession: [],
      relationShip: [],
      nationality: [],
      typeofRegCert: [],
      accounttype: [],
      SAccountType: 0,

      BoldToken: [{
        Token: ''
      }],
      Account: [],
      success_msg: false,
      VoucherId: 0,
      PreLoanDetails: [{}],
      IsAPR: false,
      Interest: [{
        MainInterest: 0
      }],
      gridData: [],
      modal_fullscreen: false,
      dynamic_title: "",
      dynamic_description: "",
      page: 1,
      sizePerPage: 10,
      productData: []
    };

    this.toggleTab.bind(this);
    this.obj = this;
    this.formTitle = "Monthly LOAN ";
    this.breadCrumbTitle = "Monthly LOAN ";
    this.breadCrumbItem = this.formTitle;
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/Membership";
    this.API_URL_SAVE = API_WEB_URLS.TransactionDeposit + "/0/token";
    this.pushFormName = "/dashboard";

    //Event Binding
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.btnCancel_onClick = this.btnCancel_onClick.bind(this);
    this.syno = this.syno.bind(this);

    this.btnReset_onClick = this.btnReset_onClick.bind(this);

    this.onloanamount = this.onloanamount.bind(this);
  }

  componentDidMount() {
    //Filling DropDowns
    Fn_FillListData(this.obj, "BoldToken", API_WEB_URLS.MASTER + "/0/token/BoldToken/Id/0");
    Fn_FillListData(this.obj, "Interest", API_WEB_URLS.MASTER + "/0/token/SchemeInterest/Id/" + 1);

    Fn_FillListData(this.obj, "AccountTypeScheme", API_WEB_URLS.MASTER + "/0/token/AccountTypeSchemesByAccountType/Id/14");

    const obj = JSON.parse(localStorage.getItem("authUser"));
    this.setState({ MembershipNo: obj.name, aid: obj.uid, role: obj.role });
    this.GetMemberName(obj.name);

    const { id } = this.props.match.params;
    if (id) {
      this.setState({ id: id });
      this.breadCrumbItem = "Edit " + this.formTitle;
      Fn_DisplayData(this.obj, id, this.API_URL + "/Id");
    } else {
      this.setState({ id: 0 });
    }
  }

  btnSave_onClick(event, formData) {
    const obj = JSON.parse(localStorage.getItem("authUser"));

    if (Number(this.state.LoanAmount) > 0) {
      var MemberGIN = this.state.MembershipNo;
      var F_MemberMaster = obj.uid;
      var AName = this.state.Name;
      var LoanLimit = this.state.PreLoanDetails[0].LoanLimit;
      var LoanAmount = this.state.LoanAmount;
      var TotalAmountPayable = this.state.TotalAmountPayable;
      var TotalInterestPayable = this.state.TotalInterestPayable;

      let vformData = new FormData();

      vformData.append("MemberGIN", MemberGIN);
      vformData.append("F_MemberMaster", F_MemberMaster);
      vformData.append("AName", AName);
      vformData.append("LoanLimit", LoanLimit);
      vformData.append("LoanAmount", LoanAmount);
      vformData.append("TotalAmountPayable", TotalAmountPayable);
      vformData.append("TotalInterestPayable", TotalInterestPayable);

      Fn_AddEditData(this.obj, { arguList: { id: 0, formData: vformData } }, "AddMonthlyLoan/0/token", "#", true, "LoanId", this.printtest);

      alert('Loan Applied Successfully!');
      this.props.history.push('./dashboard');
    }
    else {
      alert('Please enter Loan Amount!');
    }
  }

  btnCancel_onClick = event => {
    event.preventDefault();
    this.props.history.push(this.pushFormName);
  };

  btnReset_onClick = event => {
    this.setState({
      Balance: [{
        Balance: 0
      }],
      MemberName: [
        {
          Name: ''
        }
      ],
      MembershipNo: ''
    });
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }

  GetAccountDetail = (event) => {
    var value = event.target.value;
    Fn_FillListData(this.obj, "Accountdetail", API_WEB_URLS.MASTER + "/0/token/ListOfAccountsByMember/Id/" + this.state.MemberName[0].Id);
  }

  syno() {
    this.setState({
      success_msg: false
    });
  };

  GetMemberName = (e) => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    if (e == 3 || e == 12) {
      var value = event.target.value;
      value = value + '-';

      this.setState({
        MembershipNo: value
      });
    }
    let vformData = new FormData();
    vformData.append("Id", obj.uid);
    Fn_GetReport(this.obj, { arguList: { id: 0, formData: vformData } }, "MonthlyLoanLimit/0/token", "PreLoanDetails", true);
  }

  onloanamount(event) {
    if (Number(event.target.value) > Number(this.state.PreLoanDetails[0].LoanLimit)) {
      alert('Loan can not be more than loan limti');
      this.setState({ LoanAmount: 0, TotalInterestPayable: 0, TotalInterestPayable: 0 })
    }
    else {
      const interestRate = 10;
      const InterestAmount = (event.target.value / 100) * interestRate;
      const TotalAmount = Number(InterestAmount) + Number(event.target.value);

      this.setState({
        TotalAmountPayable: TotalAmount.toFixed(2),
        TotalInterestPayable: InterestAmount.toFixed(2)
      })
    }
  }

  render() {
    const columns = [{
      dataField: 'Month',
      text: 'Month',
      sort: true
    }, {
      dataField: 'Interest',
      text: 'Interest',
      sort: true
    }, {
      dataField: 'Principal',
      text: 'Principal',
      sort: true
    }, {
      dataField: 'RemainingBalance',
      text: 'RemainingBalance',
      sort: true
    },
    {
      dataField: 'TotalPayment',
      text: 'TotalPayment',
      sort: true
    },];

    const defaultSorted = [{
      dataField: 'Month',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 12,
      totalSize: this.state.gridData,
      custom: true,
    }

    const { SearchBar } = Search;

    var parameters = [{
      name: 'Id',
      values: [this.state.VoucherId]
    }];

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="wizard clearfix">
                      <div className="content clearfix">
                        <AvForm className="needs-validation" onValidSubmit={this.btnSave_onClick}>
                          <Card>
                            <CardBody>
                              <h4 className="card-title mb-4">Monthly LOAN</h4>

                              <Row className="mb-3">
                                <Col sm="12" className="d-flex align-items-center">
                                  <Label className="col-form-label me-3" style={{width: "200px"}}>Opening Date</Label>
                                  <AvField name="OpeningDate" value={this.state.formData.OpeningDate == undefined ? formatDate(getdate()) : formatDate(this.state.formData.OpeningDate)} type="text" className="form-control" disabled />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Col sm="12" className="d-flex align-items-center">
                                  <Label className="col-form-label me-3" style={{width: "200px"}}>Member GIN</Label>
                                  <AvField name="membershipno" onChange={this.GetMemberName} value={this.state.MembershipNo} placeholder="Member GIN" errorMessage="Enter Member GIN" validate={{ required: { value: true } }} type="text" disabled className="form-control" />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Col sm="12" className="d-flex align-items-center">
                                  <Label className="col-form-label me-3" style={{width: "200px"}}>Member</Label>
                                  <AvField name="Member" value={this.state.PreLoanDetails[0].FullName} placeholder="Member Name" errorMessage="Member Name" type="text" disabled className="form-control" />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Col sm="12" className="d-flex align-items-center">
                                  <Label className="col-form-label me-3" style={{width: "200px"}}>Loan Limit</Label>
                                  <AvField name="LoanLimit" value={this.state.PreLoanDetails[0].LoanLimit} placeholder="Loan Limit" type="text" className="form-control" disabled />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Col sm="12" className="d-flex align-items-center">
                                  <Label className="col-form-label me-3" style={{width: "200px"}}>Loan Amount Required</Label>
                                  <AvField name="LoanAmountRequired" value={this.state.LoanAmount} onChange={(event) => {
                                    Fn_ChangeStateValue(this.obj, "LoanAmount", event.target.value);
                                    this.onloanamount(event);
                                  }} placeholder="Enter Loan Amount" type="number" className="form-control no-spinner" />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Col sm="12" className="d-flex align-items-center">
                                  <Label className="col-form-label me-3" style={{width: "200px"}}>Loan Duration</Label>
                                  <AvField name="LoanDuration" value={'1 Month'} onChange={(event) => Fn_ChangeStateValue(this.obj, "LoanDuration", event.target.value)} placeholder="Loan Duration" type="text" className="form-control" disabled />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Col sm="12" className="d-flex align-items-center">
                                  <Label className="col-form-label me-3" style={{width: "200px"}}>Interest Rate</Label>
                                  <AvField name="InterestRate" value={'10%'} placeholder="Interest Rate" type="text" className="form-control" disabled />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Col sm="12" className="d-flex align-items-center">
                                  <Label className="col-form-label me-3" style={{width: "200px"}}>Payment Frequency</Label>
                                  <AvField name="PaymentFrequency" disabled value={this.state.formData.PaymentFrequency} onChange={(event) => Fn_ChangeStateValue(this.obj, "PaymentFrequency", event.target.value)} type="select" className="form-select">
                                    <option value={6} defaultValue label={"Monthly"} />
                                  </AvField>
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Col sm="12" className="d-flex align-items-center">
                                  <Label className="col-form-label me-3" style={{width: "200px"}}>Total Amount Payable</Label>
                                  <AvField name="TotalAmountPayable" value={this.state.TotalAmountPayable} placeholder="Total Amount Payable" type="number" className="form-control no-spinner" disabled />
                                </Col>
                              </Row>

                              <Row className="mb-3">
                                <Col sm="12" className="d-flex align-items-center">
                                  <Label className="col-form-label me-3" style={{width: "200px"}}>Total Interest Payable</Label>
                                  <AvField name="TotalInterestPayable" value={this.state.TotalInterestPayable} placeholder="Total Interest Payable" type="number" className="form-control no-spinner" disabled />
                                </Col>
                              </Row>

                              <div className="d-flex flex-wrap gap-2">
                                <Button type="submit" color="primary">Proceed</Button>
                                <Button type="button" color="secondary" onClick={this.btnCancel_onClick}>Exit</Button>
                              </div>

                              {this.state.success_msg && (
                                <SweetAlert
                                  title="Loan Created Successful!"
                                  success
                                  confirmBtnBsStyle="success"
                                  onConfirm={this.syno}
                                />
                              )}

                            </CardBody>
                          </Card>
                        </AvForm>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(container)(pageAddEdit_MonthlyLoan);
